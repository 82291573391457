import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const PraktischeInfo = () => {
  return (
    <Layout>
      <Seo title="Praktische info" />
      <article>
        <h1>Een afspraak maken</h1>
        <p>
          Je kan een afspraak maken via e-mail (lauren.maenen@gmail.com) of via
          telefoon (+32 472 46 40 59).
          <br />
          <br /> Het eerste gesprek zal de vorm aannemen van een intakegesprek.
          Er wordt gekeken naar moeilijkheden en hulpvraag zodat er samen
          gezocht kan worden naar een betekenisvol vervolg van de begeleiding.
          Gedurende de volgende afspraken zullen de doelstellingen meer
          geconcretiseerd worden zodat hier stap voor stap aan gewerkt kan
          worden.
          <br />
          <br /> Een intakegesprek/kennismakingsgesprek is niet bindend voor
          verdere hulpverlening. Belangrijk is dat je je veilig voelt in de
          relatie met je psycholoog.
          <br />
          <br /> De inhoud van de gesprekken vallen onder het beroepsgeheim, wat
          betekent dat er geen informatie met derden gedeeld mag worden zonder
          (schriftelijke) toestemming van de cliënt.
        </p>
      </article>
      <article>
        <h1>Tarief</h1>
        <p>
        Het tarief bedraagt €60 dat op het einde van de sessie contant of via payconiq betaald dient te
worden. Een gesprek duurt gemiddeld 50 à 60 minuten.
          <br />
          <br />
          Op dit ogenblik geldt er nog geen algemene regeling wat betreft de
          terugbetaling. Gezien elk ziekenfonds andere regels hanteert, verwijs
          ik je graag door naar je ziekenfonds om daar te informeren hoe het
          juist zit rond terugbetaling van psychologische begeleiding. Indien
          terugbetaling het geval is, ben ik uiteraard bereid om de nodige
          documenten in te vullen.
          <br />
          <br /> Een intelligentieonderzoek bij volwassenen (18+) kost 260 euro.
          Dit bestaat uit: afname test (2u), verwerking resultaten en opstellen
          van het verslag. Voor het feedbackgesprek betaal je bijkomend 60 euro.
          Indien je de originele scoreformulieren wilt, vraag ik 10 euro extra
          hiervoor.
          <br />
          <br /> De kostprijs voor een ASS-screening is afhankelijk van de tijd die je nodig hebt om de
intelligentietest en bijhorende vragenlijsten af te ronden. Per uur wordt er €65 aangerekend. Hier
komen nog de kosten van het verslag en feedbackgesprek bij.
          <br />
          <br /> De kostprijs voor een AD(H)D-onderzoek is afhankelijk van de tijd die je nodig hebt om de
intelligentietest en bijhorende vragenlijsten af te ronden. Per uur wordt er €65 aangerekend. Hier
komen nog de kosten van het verslag en feedbackgesprek bij.
        </p>
      </article>
      <article>
        <h1>Annuleren</h1>
        <p>
          Annuleren van afspraken dien je minimaal 24 uur op voorhand te doen
          door middel van telefoon of e-mail. Indien je niet komt opdagen zonder
          tijdig te annuleren, wordt alsnog het volledige bedrag van de afspraak
          aangerekend.
        </p>
      </article>
    </Layout>
  );
};

export default PraktischeInfo;
